import "./location-argentina.scss"

import * as React from "react"

import { graphql } from 'gatsby'
import Layout from "../../components/layout/layout"

import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Seo from "../../components/seo"

export const query = graphql`
  query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "argentina" }, node_locale: { eq: $language }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }

    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationArgentinaPage = ({ data }) => {
    const pageTitle = "Careers - Argentina"
    const pageDescription = "Get to Know Our Argentina Offices"
    const lang = 'EN'
    const meta = []

    const resistencia = {
        name: "Resistencia",
        text: "Located in north-eastern Argentina, Resistencia, best known as \"the city of sculptures\" is the center of a larger metropolitan area which is a hub for innovation and entrepreneurship. Our office is located in an exclusive area near the downtown where our visitors can enjoy great walks and tour some of the 500 monuments classified by UNESCO.",
    }

    const tandil = {
        name: "Tandil",
        text: "Tandil is a beautiful city located in the South of the Buenos Aires province in Argentina. It is known for its quality of life, amazing outdoors, and delicious food. Tandil is also the home of one of the oldest and largest Universities in Argentina. Its unique location, quality of life and access to High-Ed have resulted in making Tandil a remarkable hot spot for IT development.",
        secondText: "Our Tandil office has 2 main parts: a fully equipped work area, and a relaxing one that offers video games, ping pong, pool table, BBQ, etc."
    }

    const [cityName, setCityName] = React.useState(resistencia.name);
    const [cityText, setCityText] = React.useState(resistencia.text);
    const [secondText, setSecondText] = React.useState(resistencia.secondText);

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "Argentina", url: "#" }
    ]

    const location = {
        title: "Argentina",
        cities: ["Resistencia", "Tandil"],
        sectionClassName: "section-location-argentina",
        modalClassName: "modal-location-argentina"
    }

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    const setCity = (name) => {
        setCityName(name);
        switch (name) {
            case "Tandil":
                setCityText(tandil.text);
                setSecondText(tandil.secondText);
                break;
            case "Resistencia":
                setCityText(resistencia.text);
                setSecondText(resistencia.secondText);
                break;
            default:
                break;
        }
    }

    const cities = location.cities.map((name, index) =>
        <div hidden={name === cityName} key={index} className="list-item" onClick={() => setCity(name)}>{name}</div>
    );

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                <div className="max-container-pages">
                    <LocationHeader location={location} city={cityName}>
                        <span className="city-title">{cityName}</span>
                        <p className="city-text">
                            {cityText}
                        </p>

                        {secondText &&
                            <p>{secondText}</p>
                        }
                        <div className="other-locations">
                            <h5 className="other-locations-title">Other offices in Argentina</h5>
                            <div className="other-locations-content">
                                {cities}
                            </div>
                        </div>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="Argentina"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationArgentinaPage
